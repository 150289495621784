import React from "react";
import { RouteComponentProps } from "@reach/router";
import { HeaderSubPage } from "../components/HeaderSubPage/HeaderSubPage";

export const Params: React.FC<RouteComponentProps> = () => {
  return (
    <div className="bg-gray-100">
      <HeaderSubPage title="Compte" />
      <HeaderSubPage isStatic />

      <div className="container m-auto px-6 py-4">
        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Information personnelles
                </h3>
                <p className="mt-1 text-sm leading-5 text-gray-600">
                  Vous pouvez modifier vos informations personnelles.
                  <br />
                  Votre nom et prénom seront affichés sous le contenu que vous
                  publierez.
                  <br />
                  <strong>Si vous voulez rester dans l'anonymat</strong>, vous
                  pouvez tout simplement choisir un pseudo. Si finalement vous
                  changez d'avis, il suffit de <strong>supprimer</strong> le
                  pseudo.
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form action="#" method="POST">
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="first_name"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Pseudo (optionnel)
                        </label>
                        <input
                          id="first_name"
                          className="mt-1 form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="email_address"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Adresse email
                        </label>
                        <input
                          id="email_address"
                          disabled
                          className="mt-1 form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 cursor-not-allowed bg-gray-100"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="first_name"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Nom
                        </label>
                        <input
                          id="first_name"
                          className="mt-1 form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="last_name"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Prénom
                        </label>
                        <input
                          id="last_name"
                          className="mt-1 form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button className="py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-500 focus:outline-none focus:shadow-outline-blue active:bg-indigo-600 transition duration-150 ease-in-out">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="hidden sm:block">
          <div className="py-5">
            <div className="border-t border-gray-200"></div>
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Notifications
                </h3>
                <p className="mt-1 text-sm leading-5 text-gray-600">
                  Decide which communications you'd like to receive and how.
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form action="#" method="POST">
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <fieldset>
                      <legend className="text-base leading-6 font-medium text-gray-900">
                        By Email
                      </legend>
                      <div className="mt-4">
                        <div className="flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id="comments"
                              type="checkbox"
                              className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                            />
                          </div>
                          <div className="ml-3 text-sm leading-5">
                            <label
                              htmlFor="comments"
                              className="font-medium text-gray-700"
                            >
                              Comments
                            </label>
                            <p className="text-gray-500">
                              Get notified when someones posts a comment on a
                              posting.
                            </p>
                          </div>
                        </div>
                        <div className="mt-4">
                          <div className="flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                id="candidates"
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                              />
                            </div>
                            <div className="ml-3 text-sm leading-5">
                              <label
                                htmlFor="candidates"
                                className="font-medium text-gray-700"
                              >
                                Candidates
                              </label>
                              <p className="text-gray-500">
                                Get notified when a candidate applies for a job.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mt-4">
                          <div className="flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                id="offers"
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                              />
                            </div>
                            <div className="ml-3 text-sm leading-5">
                              <label
                                htmlFor="offers"
                                className="font-medium text-gray-700"
                              >
                                Offers
                              </label>
                              <p className="text-gray-500">
                                Get notified when a candidate accepts or rejects
                                an offer.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="mt-6">
                      <legend className="text-base leading-6 font-medium text-gray-900">
                        Push Notifications
                      </legend>
                      <p className="text-sm leading-5 text-gray-500">
                        These are delivered via SMS to your mobile phone.
                      </p>
                      <div className="mt-4">
                        <div className="flex items-center">
                          <input
                            id="push_everything"
                            name="push_notifications"
                            type="radio"
                            className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                          />
                          <label htmlFor="push_everything" className="ml-3">
                            <span className="block text-sm leading-5 font-medium text-gray-700">
                              Everything
                            </span>
                          </label>
                        </div>
                        <div className="mt-4 flex items-center">
                          <input
                            id="push_email"
                            name="push_notifications"
                            type="radio"
                            className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                          />
                          <label htmlFor="push_email" className="ml-3">
                            <span className="block text-sm leading-5 font-medium text-gray-700">
                              Same as email
                            </span>
                          </label>
                        </div>
                        <div className="mt-4 flex items-center">
                          <input
                            id="push_nothing"
                            name="push_notifications"
                            type="radio"
                            className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                          />
                          <label htmlFor="push_nothing" className="ml-3">
                            <span className="block text-sm leading-5 font-medium text-gray-700">
                              No push notifications
                            </span>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-500 focus:outline-none focus:shadow-outline-blue focus:bg-indigo-500 active:bg-indigo-600 transition duration-150 ease-in-out">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
