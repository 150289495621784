import React from "react"
import { PageProps } from "gatsby"

import { Params } from "../routes/Params"
import { Seo } from "../components/Seo/Seo"

export default ({ location }: PageProps) => (
  <>
    <Seo
      index={false}
      title="Mon compte | Temple du Haïku"
      description="Sur cette page, vous pouvez voir et modifier vos informations personnelles"
    />
    <Params />
  </>
)
